import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Image from 'gatsby-image'

import NotFoundPage from '../pages/404'
import Layout from '../components/Layout'
import Signup from '../components/Signup'
import Advert from '../components/Advert'
import SEO from '../components/seo'

import styles from "./blog-post.module.styl"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const image = post.frontmatter.mainImage.childImageSharp.fluid.src

    if(!post.frontmatter.published) {
      return (
        <NotFoundPage />
      )
    }

    return (
      <Layout location={this.props.location} footer={"basic"} title={siteTitle}>
        <SEO
          title={ post.frontmatter.title }
          description={ post.frontmatter.intro }
          kind={"article"}
          image={image} />

        <div className={ styles.blogPost }>
          <h1>{post.frontmatter.title}</h1>
          <div className={ styles.intro }>
            <p>{post.frontmatter.intro}</p>
            <Advert service={post.frontmatter.service} />
          </div>
          <div className={styles.mainImage}>
            <Image fluid={post.frontmatter.mainImage.childImageSharp.fluid} />
          </div>
          <div className={styles.body}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
          <div className={ styles.newsletter }>
            <Signup size={ "short" } />
          </div>
          <div className={ styles.meta }>
            <h3>{post.frontmatter.category}</h3>
            <div className={ styles.date }>{post.frontmatter.date}</div>
            <div className={ styles.bio }>
              <div className={ styles.avatar }>
                <Image fixed={this.props.data.avatar.childImageSharp.fixed} />
              </div>
              <div>
                Rany Keddo is a principal data science consultant at FELD. He
                lives and works in Berlin, Germany, and Wellington, New
                Zealand.
              </div>
            </div>
            <div>
          </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    avatar: file(absolutePath: { regex: "/headshot.png/" }) {
      childImageSharp {
        fixed(width: 40 height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 250)
      frontmatter {
        title
        intro
        category
        service
        published
        date(formatString: "DD. MMMM YYYY")
        mainImage {
          childImageSharp {
            fluid(maxWidth: 620) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      body
    }
  }
`
