import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

import styles from "./404.module.styl"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout footer={ "minimal" } location={this.props.location}>
        <SEO title="404: Not Found" />
        <div className={ styles.notFound }>
          <h1>Not found</h1>
          <p>
            It looks like this page doesn't exist.
            Try finding your way back through our <Link to="/articles">articles</Link> or the <Link to="/">main page</Link>.
          </p>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage
